<template>
  <div class="copyright">@2022 Therma-Stor LLC. All Rights Reserved. App Version: 2.11.</div>
</template>
<style lang="scss" scoped>
.copyright {
  color: var(--placeholder);
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'TheFooter',
  setup() {
    const version = ref<string>();

    onMounted(() => {
      version.value = process.env.VUE_APP_VERSION;
    });

    return { version };
  }
});
</script>
